export enum Environment {
	DEVELOPMENT = 'development',
	PRODUCTION = 'production',
}

export interface Parameters {
	[key: string]: any;
}

export interface SdkConfig {
	mode: Environment
}

export class ParametersConfig {
	protected readonly config: Parameters;

	constructor(config: Parameters) {
		this.config = config;
	}

	get(name: string): any {
		if (this.config[name] === undefined) {
			throw new Error(`Trying to access non-existent config parameter "${name}"`);
		}
		return this.config[name];
	}

	set(name: string, value: any): void {
		this.config[name] = value;
	}
}

export class Config extends ParametersConfig {
	getBaseUrl(): string | null {
		// return this.get('baseUrl');
		return '';
	}

	getIppFbUrl(): string {
		return this.get('ipp')['ippFbUrl'];
	}

	getIppGoogleUrl(): string {
		return this.get('ipp')['ippGoogleUrl'];
	}

	getIppAppleUrl(): string {
		return this.get('ipp')['ippAppleUrl'];
	}

	isPortalBar(): boolean {
		return this.config['portalBar'] != null;
	}

	getPortalBarHtml(): string {
		return (this.config['portalBar'] != null) ? this.get('portalBar')['html'] : null;
	}

	getPortalBarJs(): string {
		return (this.config['portalBar'] != null) ? this.get('portalBar')['js'] : null;
	}

	getPortalBarCss(): string {
		return (this.config['portalBar'] != null) ? this.get('portalBar')['css'] : null;
	}

	getGameId(): string {
		return this.get('gameId');
	}

	getMarketId(): string {
		return this.get('marketId');
	}

	getLocale(): string {
		return this.get('locale');
	}

	isMonolingual(): boolean {
		return this.config['monolingual'] ?? false;
	}

	getLandingPageId(): string {
		return this.get('landingPageId');
	}

	getPartnerPrefix(): string {
		return this.get('partnerPrefix');
	}

	getCampaignId(): string {
		return this.get('campaignId');
	}

	getBannerId(): string {
		return this.get('bannerId');
	}

	getCreativeId(): string {
		return this.get('creativeId');
	}

	getPublisherId(): string {
		return this.get('publisherId');
	}

	getBackendRequestEventId(): string {
		return this.get('backendRequestEventId');
	}

	getCdnAssetUrlPrefix() {
		return this.get('cdn') + '/bundle/' + this.get('bundle');
	}

	getCdnAssetUrl(path: string) {
		return this.getCdnAssetUrlPrefix() + '/' + path;
	}

	getOptionalSnippets(): Array<string> {
		if (this.get('snippets')['optional'] === undefined) {
			throw new Error('Optional tracking snippets are not set!');
		}
		return this.get('snippets')['optional'];
	}

	getMandatorySnippets(): Array<string> {
		if (this.get('snippets')['mandatory'] === undefined) {
			throw new Error('Mandatory tracking snippets are not set!');
		}
		return this.get('snippets')['mandatory'];
	}

	getPortalSsoErrorCode(): string | null {
		if (this.config['portalSsoError'] && this.config['portalSsoError']['errorCode']) {
			return this.get('portalSsoError')['errorCode'];
		} else {
			return null;
		}
	}

	getSsoConfirmationPath(): string | null {
		if (this.config['ssoConfirmationPath'] && this.config['ssoConfirmationPath']['path']) {
			return this.get('ssoConfirmationPath')['path'];
		} else {
			return null;
		}
	}

	getPasswordForgottenToken(): string | null {
		if (this.config['forgotPw'] && this.config['forgotPw']['token']) {
			return  this.get('forgotPw')['token'];
		} else {
			return null;
		}
	}

	getPasswordForgottenErrorCode(): string | null {
		if (this.config['forgotPw'] && this.config['forgotPw']['errorCode']) {
			return this.get('forgotPw')['errorCode'];
		} else {
			return null;
		}
	}

	getPcn(): string | null {
		return this.config['pcn'] ? this.get('pcn') : null;
	}

	getSingularDeepLink(): string | null {
		return this.config['deeplink'] ? this.get('deeplink') : null;
	}

	isSingularForwardParams(): boolean {
		return this.config['forwardParams'] ? this.get('forwardParams') : false;
	}

	/**
	 * A boolean flag providing Information if the client is a Bot or not (for example GoogleBot for Site Indexing would be true).
	 * This value gets filled by the server via <a href="https://deviceatlas.com/resources/available-properties">DeviceAtlas</a>
	 */
	isBot(): boolean {
		return this.config['bot'] ? this.get('bot') : false;
	}

	/**
	 * A boolean flag providing Information if the client is used in a spam use case or not (for example an Ad Bot Client).
	 * This value gets filled by the server via <a href="https://deviceatlas.com/resources/available-properties">DeviceAtlas</a>
	 *
	 */
	isSpam(): boolean {
		return this.config['spam'] ? this.get('spam') : false;
	}

	getOperatingSystem(): string {
		return this.get('operatingSystem');
	}

	getDeviceType(): string {
		return this.get('deviceType');
	}

	isMobileRequest(): boolean {
		return this.getDeviceType().toLowerCase() === 'mobile';
	}
}

export class DesignParams extends ParametersConfig {
}

export interface RuntimeConfig {
	config: Parameters,
	designParams: Parameters,
}
